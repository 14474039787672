import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks';

import '../css/application.css';
import "../stylesheets/application";
import "bootstrap";

require('@rails/ujs').start();
require('turbolinks').start();

require.context('../assets/', true);

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('vue-app') !== null) {
    new Vue({
      el: '#vue-app',
    });
  }
});

